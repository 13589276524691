const getTop = function(element, start) {
  // return value of html.getBoundingClientRect().top ... IE : 0, other browsers : -pageYOffset
  if (element.nodeName === "HTML") return -start;
  return element.getBoundingClientRect().top + start;
};
function easeInOutCubic(t) {
  return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
}

export const scroll = id => {
  if (typeof window !== "object" || window.pageYOffset === undefined) return;

  let duration = 1000;
  let offset = 0;
  let container = window;

  if (typeof container === "string") {
    container = document.querySelector(container);
  }

  const scrollTo = document.getElementById(id.substring(1));
  if (!scrollTo) return; // Do not scroll to non-existing node

  // Using the history api to solve issue: back doesn't work
  // most browser don't update :target when the history api is used:
  // THIS IS A BUG FROM THE BROWSERS.
  // if (window.history.pushState && location.hash !== id)
  //   window.history.pushState("", "", id);

  const startPoint = container.scrollTop || window.pageYOffset;
  // Get the top position of an element in the document
  // return value of html.getBoundingClientRect().top ... IE : 0, other browsers : -pageYOffset
  let end = getTop(scrollTo, startPoint);

  // Ajusts offset from the end
  end += offset;

  const clock = Date.now();
  const step = function() {
    // the time elapsed from the beginning of the scroll
    const elapsed = Date.now() - clock;
    // calculate the scroll position we should be in
    let position = end;
    if (elapsed < duration) {
      position =
        startPoint + (end - startPoint) * easeInOutCubic(elapsed / duration);

      requestAnimationFrame(step);
    } else {
      // this will cause the :target to be activated.
    }

    container === window
      ? container.scrollTo(0, position)
      : (container.scrollTop = position);
  };
  step();
};
