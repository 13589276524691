<template>
  <header class="header-global">
    <base-nav class="navbar-main" transparent type effect="light" expand>
      <router-link slot="brand" class="navbar-brand mr-lg-5" to="/">
        <img
          src="/img/brand/white.png"
          alt="adler logo"
          style="height: 55px;"
        />
      </router-link>

      <div class="row" slot="content-header" slot-scope="{ closeMenu }">
        <div class="col-6 collapse-brand">
          <a href="https://adler.ai">
            <img src="/img/brand/black.svg" />
          </a>
        </div>
        <div class="col-6 collapse-close">
          <close-button @click="closeMenu"></close-button>
        </div>
      </div>
      <ul class="navbar-nav align-items-lg-center ml-lg-auto">
        <ul class="navbar-nav navbar-nav-hover align-items-lg-center">
          <!-- <base-dropdown class="nav-item" menu-classes="dropdown-menu-xl">
            <a slot="title" href="#" class="nav-link" data-toggle="dropdown" role="button">
              <i class="ni ni-ui-04 d-lg-none"></i>
              <span class="nav-link-inner--text">Products</span>
            </a>
            <div class="dropdown-menu-inner">
              <router-link to="/ticketing" class="media d-flex align-items-center">
                <div class="icon icon-shape bg-gradient-primary rounded-circle text-white">
                  <i class="fa fa-ticket"></i>
                </div>
                <div class="media-body ml-3">
                  <h6 class="heading text-primary mb-md-1">Ticketing</h6>
                  <p
                    class="description d-none d-md-inline-block mb-0"
                  >Let us retain your customers when you go out to acquire new ones with our AI powered Ticket Management System.</p>
                </div>
              </router-link>
              <router-link to="/?moveTo=evoting" class="media d-flex align-items-center">
                <div class="icon icon-shape bg-gradient-warning rounded-circle text-white">
                  <i class="ni ni-ui-04"></i>
                </div>
                <div class="media-body ml-3">
                  <h5 class="heading text-warning mb-md-1">E-Voting</h5>
                  <p
                    class="description d-none d-md-inline-block mb-0"
                  >E-Voting ensures election integrity, saves serious hours and improves turnout for remote voting or for voting at meetings.</p>
                </div>
              </router-link>
            </div>
          </base-dropdown> -->
          <!-- <base-dropdown tag="li" class="nav-item">
            <a slot="title" href="#" class="nav-link" data-toggle="dropdown" role="button">
              <i class="ni ni-collection d-lg-none"></i>
              <span class="nav-link-inner--text">Examples</span>
            </a>
            <router-link to="/profile" class="dropdown-item">Profile</router-link>
            <router-link to="/login" class="dropdown-item">Login</router-link>
            <router-link to="/register" class="dropdown-item">Register</router-link>
          </base-dropdown>-->
        </ul>
        <a href="https://my.adler.ai/login" class="btn btn-neutral btn-icon">
          <span class="btn-inner--icon">
            <i class="fa fa-sign-in mr-2"></i>
          </span>
          <span class="nav-link-inner--text">Login</span>
        </a>
      </ul>
    </base-nav>
  </header>
</template>
<script>
import BaseNav from "@/components/BaseNav";
import BaseDropdown from "@/components/BaseDropdown";
import CloseButton from "@/components/CloseButton";

export default {
  components: {
    BaseNav,
    CloseButton,
    BaseDropdown
  }
};
</script>
<style></style>
